@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.AvailableCredits {
    &-Value {
        @include mobile {
            text-align: center;
            font-size: 14px;
        }

        span {
            @include mobile {
                display: block;
            }
        }
    }

    &-NoCredits {
        display: block;
        font-size: 16px;
        padding: 40px 0;
    }
}
